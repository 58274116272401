<template>
	<div>
		<div class="contacts--list__item">
			<label class="check">
				<input type="checkbox" />
				<div class="check-mark"></div>
			</label>
			<p>
				{{ client.client_id }}
			</p>
			<p>
				{{ client.client_key }}
			</p>
			<p>
				{{ client.client_secret }}
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ClientItem',
	props: {
		clientData: {
			type: Object,
			default() {
				return {};
			}
		}
	},
	data() {
		return {
			client: this.clientData,
			itemData: '',
			mapStatus: ''
		};
	}
};
</script>

<style scoped>
.badge {
	font-weight: normal;
}
.contacts--list__item {
	display: grid;
	grid-template-columns: 35px 2fr 100px 100px 120px 2fr 150px;
}
.info__primary {
	width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	margin-right: 10px;
}
.info__email {
	text-transform: lowercase;
}
.contacts__info {
	overflow: hidden;
}
</style>
